import { Controller } from "@hotwired/stimulus"

// Will toggle the parent row class of an element.
// Expects a class value for the row to be set ex:
// data-row-toggle-toggle-class-value="bg-red"
export default class extends Controller {
  static values = {
    toggleClass: String
  }

  toggle(event) {
    $(this.element).closest('tr').toggleClass(this.toggleClassValue);
  }
}
