import { Controller } from "@hotwired/stimulus"

// This works but expects the first field to be date_installed.
// Need to refactor but not urgent.
export default class extends Controller {
  static targets = ["source", "target"]

  connect() {
    console.log("fields copier connected.")
  }

  copyFields(event) {
    event.preventDefault();

    const sourceFields = $(this.sourceTarget).find('input[type="text"]');
    const targetContainer = $(event.target).closest('[data-fields-copier-target="target"]');
    const targetIndex = this.getTargetIndex(targetContainer);

    if (targetIndex === null) {
      console.error("Could not determine target index");
      return;
    }

    sourceFields.each((_, sourceField) => {
      this.processSourceField($(sourceField), targetIndex, targetContainer);
    });
  }

  getTargetIndex(targetContainer) {
    const firstInputName = targetContainer.find('input[type="text"]').first().attr('name');
    const match = firstInputName.match(/\[subcomponent_items_attributes\]\[(\d+)\]/);
    return match ? match[1] : null;
  }

  processSourceField($sourceField, targetIndex, targetContainer) {
    const sourceValue = $sourceField.val();
    const targetFieldName = this.determineTargetFieldName($sourceField.attr('name'), targetIndex);

    if (targetFieldName) {
      this.updateTargetField(targetContainer, targetFieldName, sourceValue);
    }
  }

  determineTargetFieldName(sourceFieldName, targetIndex) {
    const baseName = 'maintenance_tracking_item[subcomponent_items_attributes][';
    if (sourceFieldName.includes('maintenance_tracking_item_install_values_attributes')) {
      const match = sourceFieldName.match(/\[(\d+)\]\[value\]$/);
      return match ? `${baseName}${targetIndex}][maintenance_tracking_item_install_values_attributes][${match[1]}][value]` : null;
    }

    return `${baseName}${targetIndex}][date_installed]`;
  }

  updateTargetField(targetContainer, targetFieldName, sourceValue) {
    const $targetField = targetContainer.find(`input[name="${targetFieldName}"]`);
    if ($targetField.length) {
      $targetField.val(sourceValue);
    }
  }
}
