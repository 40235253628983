// This example controller works with specially annotated HTML like:
//
//  <h4>Tasks</h4>
//  <div data-controller="nested-form">
//    <template data-target="nested-form.template">
//      <%= form.fields_for :tasks, Task.new, child_index: 'NEW_RECORD' do |task| %>
//        <%= render "task_fields", form: task %>
//      <% end %>
//    </template>
//
//    <%= form.fields_for :tasks do |task| %>
//      <%= render "task_fields", form: task %>
//    <% end %>
//
//    <div class="mb-3" data-target="nested-form.links">
//      <%= link_to "Add Task", "#", class: "btn btn-outline-primary", data: { action: "click->nested-form#addAssociation" } %>
//    </div>
//  </div>
//
//  # _task_fields.html.erb
//  <%= content_tag :div, class: "nested-fields", data: { new_record: form.object.new_record? } do %>
//    <div class="form-group">
//      <%= form.label :description %>
//      <%= form.text_field :description, class: 'form-control' %>
//      <small><%= link_to "Remove", "#", data: { action: "click->nested-form#removeAssociation" } %></small>
//    </div>
//
//    <%= form.hidden_field :_destroy %>
//  <% end %>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields";
  }

  addAssociation(event) {
    event.preventDefault()
    // This would allow a limit.
    // if (this.totalCurrentElements() > 2) return

    const newRecordId = new Date().getTime();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, newRecordId);
    const div = document.createElement('div');
    div.innerHTML = content;

    this.linksTarget.insertAdjacentHTML('beforebegin', div.innerHTML)

    let wrapper = event.target.closest(this.wrapperClass)
    const addEvent = new CustomEvent('add-association', { detail: { newRecordId: newRecordId } })
    window.dispatchEvent(addEvent)
  }

  removeAssociation(event) {
    event.preventDefault();
    if (!confirm("Are you sure you want to remove these fields?")) return;

    let wrapper = event.target.closest("." + this.wrapperClass)

    // Remove new records, or flag saved ones for deletion.
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
    }

    // Dispatch this event, with the containing class name.
    // This can be listened for if it should trigger another event, ex. recalculation.
    const removeEvent = new CustomEvent('remove-association', { detail: { className: wrapper.className } })
    window.dispatchEvent(removeEvent)
  }

  totalCurrentElements() {
    const inputElementsLength = this.element.querySelectorAll('.nested-fields').length
    if (!inputElementsLength) return 0

    const destroyElements = this.element.querySelectorAll("input[name*='_destroy']")
    const selectedForDestroyLength = [...destroyElements].filter(e => e.value == '1').length
    return inputElementsLength - selectedForDestroyLength
  }
}
