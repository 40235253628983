import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"
import { patch } from '@rails/request.js'

// If using a handle class, add `data-sortable-handle=".fa-sort"`
// If using a draggable class, add  data-sortable-draggable=".draggable"
export default class extends Controller {
  connect() {
    let options = {
      onEnd: this.end.bind(this),
      forceFallback: true,
      animation: 150
    }

    const handle = this.data.get('handle')
    if (handle) options['handle'] = handle

    const draggable = this.data.get('draggable')
    if (draggable) options['draggable'] = draggable

    this.sortable = Sortable.create(this.element, options)
  }

  end(event) {
    let data = new FormData()
    data.append('position', event.newDraggableIndex)

    patch(event.item.dataset.sortableUrl, {
      body: data,
      responseKind: 'turbo-stream'
    })
  }
}
