import { Controller } from "@hotwired/stimulus"

// Toggle sections of page to add / remove hide-for-print class, so only a single section or all is printed.
// allSelectors value should be an array of all selectors used for sections.
export default class extends Controller {
  static values = {
    allSelectors: Array
  }

  // Removes hide for print from all the selectors.
  removeAllAndPrint(event) {
    for(const selector of this.allSelectorsValue) {
      this.removeHideForPrint(selector);
    }
    window.print();
  }

  // Prints only the data in selector for targets data-selector value.
  removeExceptAndPrint() {
    for(const selector of this.allSelectorsValue) {
      this.addHideForPrint(selector);
    }
    this.removeHideForPrint(event.target.dataset.selector);
    window.print()
  }

  removeHideForPrint(selector) {
    $(selector).removeClass('hide-for-print');
  }

  addHideForPrint(selector) {
    $(selector).addClass('hide-for-print');
  }
}
