import { Controller } from "@hotwired/stimulus"

// Will toggle the visibility of the div id set by data value
//
// target-id-value should be the id of the element to toggle
export default class extends Controller {
  static values = {
    targetId: String
  }

  toggle(event) {
    $(`#${this.targetIdValue}`).toggle();
  }
}
