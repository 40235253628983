import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).datepicker({dateFormat: "yy-mm-dd"});
  }

  disconnect() {
    $(this.element).datepicker("destroy");
  }
}
