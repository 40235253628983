import { Controller } from "@hotwired/stimulus"

// Quick fix for Foundation modal.
// Add this controller to the current elements to open on click (or choose other action).
// Foundation should be migrated out at some point and modals replaced with stimulus/hotwire.
// Defaults to close_on_background_click false, but can be changed by setting data param.
// Url can be added also as a data param.
// To auto close a parent modal on a successful response, wrap the request in a turbo frame, and add the submitEnd action as follows:
// tag.div data: { controller: 'foundation-modal', action: 'turbo:submit-end->foundation-modal#submitEnd' }
export default class extends Controller {
  static values = {
    closeOnBackground: Boolean,
    url: String
  }

  // Add the controller and data-display-onload="true" to an element to load a modal on page load.
  connect() {
    if (this.element.dataset.displayOnload) {
      $(`#${this.element.id}`).foundation('reveal', 'open', this.modalOptions());
    }
  }

  openModal() {
    $(`#${this.element.dataset.revealId}`).foundation('reveal', 'open', this.modalOptions());
  }

  submitEnd(event) {
    if (event.detail.success) {
      $(this.element).closest('.reveal-modal').foundation('reveal', 'close');
    }
  }

  modalOptions() {
    let options = {
      close_on_background_click: (this.closeOnBackground || false),
      no_scroll: false
    };
    if (this.urlValue) options['url'] = this.urlValue;

    return options;
  }
}
