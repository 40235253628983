import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async submitAll() {
    const selectedPurchaseOrderId = $("#purchase_order_all_id").val();
    if (!selectedPurchaseOrderId) return

    const forms = document.querySelectorAll(".purchase-order-form");
    for (const form of forms) {
      $(form).find('[name="purchase_order_id"]').val(selectedPurchaseOrderId);
      await this.submitForm(form);
    }
  }

  submitForm(form) {
    return new Promise((resolve) => {
      form.addEventListener("turbo:submit-end", () => resolve(), { once: true });
      form.requestSubmit();
    });
  }
}
