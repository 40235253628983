import { Controller } from "@hotwired/stimulus"

// Will toggle related checkboxes of the target class value
export default class extends Controller {
  static values = {
    targetClass: String
  }

  toggle(event) {
    $(`input.${this.targetClassValue}[type='checkbox']`).prop('checked', event.target.checked);
  }
}
