import { Controller } from "@hotwired/stimulus"

// This is only used on Maintenance Tracking Items Edit page.
// This listens to an add event for subcomponents, and sets the order field to the next incremental value,
// after the last order, adding .001 to each, starting at .001.
// This starts on the order of the parent, or zero if the parent does not have an order.
export default class extends Controller {
  static targets = ["field"]

  initialize() {
    this.updateOrder = this.updateOrder.bind(this);
  }

  connect() {
    window.addEventListener('add-association', this.updateOrder);
  }

  disconnect() {
    window.removeEventListener('add-association', this.updateOrder);
  }

  updateOrder(event) {
    const parentOrderField = document.querySelector('input[name="maintenance_tracking_item[order]"]');
    let baseOrder = parseFloat(parentOrderField.value) || 0.0;

    const subcomponentItems = this.element.querySelectorAll('input[name^="maintenance_tracking_item[subcomponent_items_attributes]"][name$="[order]"]');
    // let highestOrder = 0.000;
    subcomponentItems.forEach((item) => {
      const value = parseFloat(item.value);
      if (!isNaN(value) && value > baseOrder) {
        baseOrder = value;
      }
    });

    const newOrder = (baseOrder + 0.001).toFixed(3);
    const newItemOrderField = this.element.querySelector(`input[name^="maintenance_tracking_item[subcomponent_items_attributes][${event.detail.newRecordId}][order]"]`);
    if (newItemOrderField) {
      newItemOrderField.value = newOrder;
    }
  }
}
