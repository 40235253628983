import { addDays, addMonths, addYears, formatISO9075 } from 'date-fns'
import { Controller } from "@hotwired/stimulus"

// Updates nextDate target, based on the values in startDate, type, and number.
// startDate: text field with date in YYYY-MM-DD
// type: select where value 'days', 'months', 'years' can be selected.
// number: number field
// nextDate: text field that will be updated with the calculation ex startDate + 3 months
export default class extends Controller {
  static targets = ["startDate", "type", "number", "nextDate",]
  static values = { flightReports: Object }

  updateNextDate() {
    if (!this.startDateTarget.value || !this.typeTarget.value || !this.numberTarget.value) return;

    const startDate = Date.parse(this.startDateTarget.value);
    let nextDate;

    switch(this.typeTarget.value) {
      case 'days':
        nextDate = addDays(startDate, this.numberTarget.value);
        break;
      case 'months':
        nextDate = addMonths(startDate, this.numberTarget.value);
        break;
      case 'years':
        nextDate = addYears(startDate, this.numberTarget.value);
        break;
    }

    this.nextDateTarget.value = formatISO9075(nextDate, { representation: 'date' })
  }
}
