import SignaturePad from "signature_pad";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.canvas = document.querySelector("canvas");

    if (this.canvas) {
      this.canvasContainer = document.getElementById('signature-pad-body');
      this.signaturePad = new SignaturePad(this.canvas, { dotSize: 2.0, minWidth: 2, maxWidth: 4 });

      $(this.element).submit(() => {
        if (!this.signaturePad.isEmpty()) {
          $('.signature-pad-input').val(this.signaturePad.toDataURL());
        }
      });

      window.addEventListener("resize", () => this.resizeCanvas());
      this.resizeCanvas();
    }
  }

  clear() {
    this.signaturePad.clear();
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    this.canvas.width = this.canvasContainer.offsetWidth;// * ratio;
    this.canvas.height = this.canvas.width / 3;

    // this.canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad.clear();
  }
}
