import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Will a call a get turbo-stream url with the given inputs name and value.
// Expected use would be to call a turbo endpoint which would update another page value.
//
// Works for checkbox, radio, and select.
// Will default to get, but can pass in http-method to make other requests.
// Optional: set data-action-on-input-change-target="targeted"
// This will pass that elements DOM ID to the request, so that the response knows which element to update.
// If that element does not have an ID, a random one will automatically be assigned.
export default class extends Controller {
  static targets = ["targeted"]
  static values = {
    url: String,
    httpMethod: String
  }

  // Random ID if targeted field does not have one.
  connect() {
    if (this.hasTargetedTarget && this.targetedTarget.id === "") {
      this.targetedTarget.id = Math.random().toString(36)
    }
  }

  async callAction(event) {
    const method = this.httpMethodValue || "get";
    const eventTarget = event.target

    // select-one, radio, or checked
    const selectedValue = eventTarget.type != "checkbox" ? eventTarget.value : eventTarget.checked;

    let params = new URLSearchParams()
    params.append(event.target.name, selectedValue)
    if (this.hasTargetedTarget) {
      params.append("targeted", this.targetedTarget.id)
    }

    const request = new FetchRequest(method, this.urlValue, {
      responseKind: "turbo-stream", query: params
    })

    await request.perform();
  }
}
