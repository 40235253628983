// Use to add multiple models in a single form.
// See example forms and controllers to add correctly.
// This only works for new forms currently, but haven't worked on code to edit / delete saved records.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "multiple-fields"
  }

  addModel(event) {
    event.preventDefault()
    // if (this.totalCurrentElements() > 2) return

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeModel(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    //if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    // } else {
    //   wrapper.querySelector("input[name*='_destroy']").value = 1
    //   wrapper.style.display = 'none'
    // }

    // Dispatch this event, with the containing class name.
    // This can be listened for if it should trigger another event, ex. recalculation.
    const removeEvent = new CustomEvent('remove-association', { detail: { className: wrapper.className } })
    window.dispatchEvent(removeEvent)
  }

  totalCurrentElements() {
    const inputElementsLength = this.element.querySelectorAll('.multiple-fields').length
    if (!inputElementsLength) return 0

    const destroyElements = this.element.querySelectorAll("input[name*='_destroy']")
    const selectedForDestroyLength = [...destroyElements].filter(e => e.value == '1').length
    return inputElementsLength - selectedForDestroyLength
  }
}
