import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal'];
  modal = null;

  connect() {
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        $(this.element).closest('.reveal-modal').foundation('reveal', 'close');
      }
    });
  }
}
