import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "target"]

  connect() {
    console.log("copy current values connected.")
  }

  copy(event) {
    event.preventDefault();
    $(this.element).find('input[name*="[value]"]').each(function() {
      const $parentDiv = $(this).closest('div.columns.end')
      const parameterId = $parentDiv.find('input[name*="[aircraft_parameter_id]"]').val()
      const hiddenValue = $(this).closest('div[data-controller="copy-current-values"]').find(`input[name="current_values[${parameterId}]"]`).val()
      $(this).val(hiddenValue || "")
    })
  }
}
