import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "selectAll"]

  toggleAll(event) {
    const checked = event.target.checked;
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = checked;
    });
  }
}
