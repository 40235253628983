import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// On connect/load, this will request the next path set by
// data-load-next-path `path` value.
// This can be used with a turbo stream to continually append to a list.
export default class extends Controller {
  connect() {
    const path = this.data.get('path')
    this.loadNext(path)
  }

  disconnect() {
    this.data.set('path', null)
  }

  loadNext(path) {
    get(path, { responseKind: 'turbo-stream' })
  }
}
