import { Controller } from "@hotwired/stimulus"
import 'selectize'

export default class extends Controller {
  static values = { url: String }

  connect() {
    $(this.element).selectize({
      valueField: 'value',
      labelField: 'text',
      searchField: 'text',
      load: (query, callback) => {
        if (!query.length) return callback();
        fetch(`${this.urlValue}?q=${encodeURIComponent(query)}`)
          .then(response => response.json())
          .then(json => callback(json))
          .catch(() => callback());
      }
    });
  }
}
