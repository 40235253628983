import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]

  connect() {
    console.log("alternating background connected.")
    this.observer = new MutationObserver(() => this.alternateBackgrounds())
    this.observer.observe(this.element, { childList: true, subtree: true })
    this.alternateBackgrounds()
  }

  disconnect() {
    this.observer.disconnect()
  }

  alternateBackgrounds() {
    this.itemTargets.forEach((element, index) => {
      element.classList.remove('odd', 'even')
      if (index % 2 === 0) {
        element.classList.add('odd')
      } else {
        element.classList.add('even')
      }
    })
  }
}
