import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    flightReports: Object
  }

  connect() {
    this.updateTotalHrs();
    this.updateItemsTotalHrs();
  }

  updateTotalHrs() {
    const onDutyHr = $('#crew_position_on_duty_hr').val();
    const onDutyMin = $('#crew_position_on_duty_min').val();
    const offDutyHr = $('#crew_position_off_duty_hr').val();
    const offDutyMin = $('#crew_position_off_duty_min').val();
    const lunchHr = $('#crew_position_lunch_hr').val();
    const lunchMin = $('#crew_position_lunch_min').val();
    const onDuty = ((onDutyHr*3600)+(onDutyMin*60)) / 3600;
    const offDuty = ((offDutyHr*3600)+(offDutyMin*60)) / 3600;
    const lunchTime = ((lunchHr*3600)+(lunchMin*60)) / 3600;
    const totalHrs = onDuty > offDuty ? ((offDuty + 24) - onDuty) - lunchTime : ((offDuty - onDuty) - lunchTime);
    $('#hrs-worked').html(totalHrs.toFixed(2));
  }

  updateItemsTotalHrs() {
    let totalHours = 0;

    $('.item-hours').each(function() {
      const hours = $(this).val();
      const minutes = $(this).closest('.row').find('.item-minutes').val()
      totalHours += ((hours * 3600) + (minutes * 60)) / 3600;
    });

    $('#total-item-hours').html(totalHours.toFixed(2));
  }

  updateFlightReportDetails(event) {
    const flightReport = this.flightReportsValue[event.target.value];
    $('#crew_position_time_rev').val(flightReport[0]);
    $('#crew_position_time_non_rev').val(flightReport[1]);
    $('#crew_position_date').val(flightReport[2]);
    $('#crew_position_aircraft').val(flightReport[3]);
    $('#crew_position_client').val(flightReport[4]);
    $('#crew_position_location').val(flightReport[5]);
  }

  // Can probably be updated to use turbo frame
  showWorkOrdersForAircraft(event) {
    const elementId = event.target.id.match(/[0-9]+/g)[0];
    const selectBox = $('#crew_position_crew_position_items_attributes_' + elementId + '_work_order_id');

    Rails.ajax({
      type: "POST",
      url: `/employee/work-orders/wos`,
      data: `aircraft=${event.target.value}`,
      success: (data, _status, xhr) => {
        let i, options;
        options = "<option></option>";
        i = 0;
        while (i < data.length) {

          options += `<option value='${data[i].work_order.id}'>${data[i].work_order.number} - ${data[i].work_order.description} ${data[i].work_order.finished ? '(Finished)' : ''} </option>`;
          i++;
        }
        selectBox.html(options);
      },
      failure: function(data) {
        alert("There was an internal error. Please contact support!");
      }
    });
  }
}
