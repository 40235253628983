import { Controller } from "@hotwired/stimulus"

// Used for sms management calculations.
export default class extends Controller {
  static values = {
    fuels: Object,
    clients: Object
  }

  connect() {
    this.updateFlightDiff();
    this.updateTotalRev();
    this.updateTotalNonRev();
  }

  calculateFuel(event) {
    event.preventDefault();
    let fuelRange = this.fuelsValue[$('#sms_management_flight_report_attributes_aircraft').val()];
    let totalRev = $('#total-rev').html();
    let customerFuel = $('#sms_management_flight_report_attributes_fuel_customer').val();
    let appCompany = totalRev * fuelRange;
    let appCompanyFuel = appCompany - customerFuel;
    $('#sms_management_flight_report_attributes_fuel_appCompany').val(appCompanyFuel);
    return false
  }

  // Called on window event of removal of remote-association.
  // Checks if rev-row removed before performing recalculations.
  recalculateRevs(event) {
    if (event && event.type == "remove-association" && !event.detail["className"].includes("rev-row")) return;

    this.updateTotalNonRev()
    this.updateTotalRev()
  }


  // This will be called on page load, on field blur.
  updateTotalNonRev() {
    let totalNonRev = 0;
    $('.data-non-rev:visible').each(function() {
      if ($(this).val() !== "") {
        totalNonRev += parseFloat($(this).val());
      }
    });
    $('#total-non-rev').html(parseFloat(totalNonRev).toFixed(2));
  }

  // This will be called on page load, on field blur
  updateTotalRev() {
    let totalRev = 0;
    $('.data-rev:visible').each(function() {
      if ($(this).val() !== "") {
        totalRev += parseFloat($(this).val());
      }
    });
    $('#total-rev').html(parseFloat(totalRev).toFixed(2));
  }

  updateFlightDiff() {
    let flightDiff, flightDiffFinal, flightFinish, flightStart;
    flightFinish = $('#sms_management_flight_report_attributes_finish').val();
    flightStart = $('#sms_management_flight_report_attributes_start').val();
    flightDiffFinal = flightFinish - flightStart;
    flightDiff = $('#time-diff');
    flightDiff.html(parseFloat(flightDiffFinal).toFixed(2));
  }

  updateClientDetails(event) {
    const clientRange = this.clientsValue[event.target.value];
    $('#sms_management_flight_report_attributes_charterer').val(clientRange[0]);
    $('#sms_management_flight_report_attributes_address1').val(clientRange[1]);
    $('#sms_management_flight_report_attributes_address2').val(clientRange[2]);
    $('#sms_management_flight_report_attributes_tel').val(clientRange[3]);
    $('#sms_management_flight_report_attributes_fax').val(clientRange[4]);
  }
}
