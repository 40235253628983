import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus"

// Add controller to form.
// requires field data-dropzone-param-name with the specific param name ie model[image_field]
export default class extends Controller {
  static values = {
    extensions: String,
    message: String
  }

  connect() {
    Dropzone.autoDiscover = false;
    const options = {
      maxFilesize: 200, // set the maximum file size to 25 MB
      paramName: this.data.get('paramName'), // Rails expects the file upload to be something like model[image_field]
      parallelUploads: 3,
      addRemoveLinks: false // don't show remove links on dropzone itself.
    }
    if (this.messageValue) options['dictDefaultMessage'] = this.messageValue
    if (this.extensionsValue) options['acceptedFiles'] = this.extensionsValue

    var dropzone = new Dropzone(this.element, options);
  }
}
