import { Controller } from "@hotwired/stimulus"
import Selectize from 'selectize'

export default class extends Controller {
  static targets = ['select', 'output'];

  connect() {
    // console.log('Text list select loaded');
    $(this.selectTarget).selectize({
      persist: false,
      allowEmptyOption: true,
      onItemAdd: (value, $item) => {
        this.addToFormula(value);
      }
    });
  }

  addToFormula(value) {
    const input = $(this.outputTarget);
    input.val(`${input.val()} ${value}`);
    input.focus();
  }

  disconnect() {
    if (this.selectTarget.selectize) {
      this.selectTarget.selectize.destroy();
    }
  }
}
