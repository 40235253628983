// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import * as Turbo from '@hotwired/turbo'
import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)
import "./jquery"
import "./jquery-ui"
import "foundation-sites-5"
import "foundation-sites-5/js/foundation/foundation.reveal"
import "foundation-sites-5/js/foundation/foundation.topbar"
import "fontawesome-free/js/all"
import "./controllers"
import "./turbo_streams"
