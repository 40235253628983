import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['source', 'target'];

  copyToTextField(event) {
    const button = $(event.currentTarget);
    const sourceElement = button.siblings('[data-copy-content-target="source"]');
    const content = sourceElement.length ? sourceElement.text().trim() : $(this.sourceTarget).text().trim();
    const shouldAppend = this.data.get('append') === 'true';

    if (shouldAppend) {
      this.targetTarget.value += content;
    } else {
      this.targetTarget.value = content;
    }
  }
}
