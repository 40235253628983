import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['source', 'target'];
  static values = { prefix: String }

  connect() {
    this.updateIndex();
  }

  updateIndex() {
    let value = this.sourceTarget.value.toLowerCase().replace(/\s+/g, '_');
    value = value && this.prefixValue ? `${this.prefixValue}_${value}` : value;

    if (this.targetTarget.tagName === 'INPUT') {
      this.targetTarget.value = value;
    } else {
      this.targetTarget.innerHTML = value;
    }
  };
}
